<template>
  <div class="dashboard-content mt-5">
    <div class="d-flex justify-content-end pb-4 pt-5">
      <div class="m-width-300 mr-2">
        <SearchBox
          bg-color="#D6DDE4"
          :rounded="5"
          placeholder="Search users by name, type..."
        />
      </div>

      <div>
        <FilterDropdown
          variant="faint-blue"
          :filters="filters"
          @filtered="setFilter"
        />
      </div>
    </div>

    <div class="billing-table m-rounded-10 mb-5 py-2">
      <b-table responsive striped borderless :items="items">
        <template #cell(mode_of_payment)="data">
          <span>
            <ModeOfPayment :payment="{...data.item, pan:'***4'}" />
          </span>
        </template>
        <template #cell(status)="data">
          <span :class="getVariant(data.item.status)">{{
            data.item.status
          }}</span>
        </template>
        <template #cell(details)>
          <router-link
            class="m-fs-12 text-tertiary ml-5 "
            :to="{
              name: 'billing-overview',
              params: { id: 'xxxx' },
            }"
          >
            <EyeIcon class="ml-5" />
            <span>
              <CloudLightningIcon class="ml-5" />
            </span>
          </router-link> 
        </template>
        <template #head(details)="data">
          <span class="d-none">{{ data.label }}</span>
        </template>
      </b-table>

      <div
        class="
          m-pagination
          overflow-auto
          d-flex
          p-4
          justify-cotnent-between
          align-items-center
        "
      >
        <span class="flex-grow-1">
          Showing page {{ currentPage }} of {{ totalRows }}
        </span>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          align="right"
          pills
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from "../../components/SearchBox.vue";
import FilterDropdown from "../../components/FilterDropdown.vue";
import EyeIcon from "../../components/svg/EyeIcon.vue";
import CloudLightningIcon from "../../components/svg/CloudLightningIcon.vue";
import ModeOfPayment from "../../components/billing/ModeOfPayment.vue";

export default {
  components: {
    SearchBox,
    FilterDropdown,
    EyeIcon,
    CloudLightningIcon,
    ModeOfPayment
},
  data() {
    return {
      filterValue: "weekly",       
      currentPage: 1,
      perPage: 3,
      statuses: {
        Received: "text-success",
        Pending: "text-warning",
      },
      links: {
        ViewDetails: "/",
      },
      filters: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "Monthly",
          value: "monthly",
        },
      ],
      items: [
        {
          date: "30/10/21",
          mode_of_payment: "visa",
          amount_paid: "$ 3000",
          status: "Pending",
          details: "",
        },
        {
          date: "30/10/21",
          mode_of_payment: "master",
          amount_paid: "$ 3000",
          status: "Received",
          details: "",
        },
        {
          date: "30/10/21",
          mode_of_payment: "visa",
          amount_paid: "$ 3000",
          status: "Pending",
          details: "",
        },
        {
          date: "30/10/21",
          mode_of_payment: "master",
          amount_paid: "$ 3000",
          status: "Received",
          details: "",
        },
        {
          date: "30/10/21",
          mode_of_payment: "visa",
          amount_paid: "$ 3000",
          status: "Pending",
          details: "",
        },
        {
          date: "30/10/21",
          mode_of_payment: "master",
          amount_paid: "$ 3000",
          status: "Received",
          details: "",
        },
      ],
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    setFilter(value) {
      console.log(value);
    },
    getVariant(status) {
      return this.statuses[status];
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-table {
  background-color: $white;
}
.btn-tertiary {
  height: 40px;
}
.details__link {
  font-size: 12px;
  color: $tertiary-color;
}

thead th, tbody td {
  padding: 8rem !important;
}
</style>
