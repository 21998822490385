<template>
  <div class="d-flex">
    <span v-if="payment.mode_of_payment === 'visa'">
      <VisaIcon />
    </span>

    <span v-else>
      <img src="../../assets/img/image--master.png" alt="master" class="img-fluid"> 
    </span>

    <span class="ml-3 pt-1">
      {{ payment.pan }}
    </span>
  </div>
</template>

<script>
import VisaIcon from "../svg/VisaIcon.vue";

export default {
  name: "ModeOfPayment",
  components: {
    VisaIcon,
  },
  props: {
    payment: {
      type: Object,
      required: true
    },
  },
};
</script>